:root {
  --primary-st-color: #2a5f73;
}

.page-item.active .page-link {
  background-color: #2a5f73 !important;
  border-color: #2a5f73 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #2a5f73 !important;
}

.color-red {
  color: red;
}

.score-edited {
  background-color: #eda4b8 !important;
  color: #212529 !important;
}

.color-blue {
  color: blue;
}

.image-menu {
  margin-top: 20px;
  margin-left: 20px;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 250px;
  height: 250px;
}

.student-avatar {
  margin-top: 30px;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 90%;
  height: 250px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.row-selected {
  color: #ffffff;
  background-color: #17a2b8;
}

.btn-form-group {
  margin-top: 34px;
}

.custom-file-label,
.custom-file-label::after {
  height: 38px;
}

/** Table header fix **/
.table-scroll {
  max-height: 20vh;
}

.table-header-color {
  background: #2a5f73;
  color: #ffffff;
}

table thead tr:nth-child(1) th {
  background: #2a5f73;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 50;
}

table thead tr:nth-child(2) th {
  background: #2a5f73;
  color: #ffffff;
  position: sticky;
  top: 47px;
  z-index: 50;
}

table thead tr th,
td {
  white-space: nowrap !important;
}

table thead tr th {
  text-align: center;
}

/* Medium devices (tablets, 600px and up) */
@media (min-height: 600px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 30vh;
  }
}

/* Medium devices (tablets, 700px and up) */
@media (min-height: 700px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 35vh;
  }
}

/* Medium devices (tablets, 800px and up) */
@media (min-height: 800px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 40vh;
  }
}

/* Medium devices (tablets, 900px and up) */
@media (min-height: 900px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 45vh;
  }
}

/* Medium devices (tablets, 1000px and up) */
@media (min-height: 1000px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 50vh;
  }
}

/* Medium devices (tablets, 1100px and up) */
@media (min-height: 1100px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 55vh;
  }
}

/* Medium devices (tablets, 1200px and up) */
@media (min-height: 1200px) {
  /** Table header fix **/
  .table-scroll {
    max-height: 60vh;
  }
}

@media (max-width: 600px) {
  .image-menu {
    margin-top: 10px;
    margin-left: 10px;
    padding: 0.15rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.15rem;
    width: 150px;
    height: 150px;
  }

  .student-avatar {
    margin-top: 30px;
    padding: 0.15rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.15rem;
    width: 90%;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .image-menu {
    margin-top: 10px;
    margin-left: 10px;
    padding: 0.15rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.15rem;
    width: 100px;
    height: 100px;
  }

  .student-avatar {
    margin-top: 30px;
    padding: 0.15rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.15rem;
    width: 90%;
    height: 100px;
  }
}

@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .table-scroll {
    height: auto;
    overflow: auto;
  }
}

/*  CUSTOM antdesign */
.ant-table tr td {
  white-space: normal !important;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: var(--primary-st-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--primary-st-color);
  border-color: var(--primary-st-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--primary-st-color);
  border-color: var(--primary-st-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: var(--primary-st-color);
  border-color: var(--primary-st-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: var(--primary-st-color);
}

table thead tr td .ant-radio-wrapper,
table thead tr td .ant-radio-disabled + span {
  color: #fff;
}

.btn-st {
  min-width: 100px;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/*.btn-outline-primary.disabled, .btn-outline-primary:disabled {*/
/*  color: #007bff;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/*.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {*/
/*  color: #6c757d;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/*.btn-outline-success.disabled, .btn-outline-success:disabled {*/
/*  color: #28a745;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/*.btn-outline-info.disabled, .btn-outline-info:disabled {*/
/*  color: #17a2b8;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*.btn-outline-warning.disabled, .btn-outline-warning:disabled {*/
/*  color: #ffc107;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/*.btn-outline-danger.disabled, .btn-outline-danger:disabled {*/
/*  color: #dc3545;*/
/*  background-color: transparent;*/
/*}*/

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.ant-form-item-label {
  line-height: 1.2 !important;
}

.anticon {
  vertical-align: 0 !important;
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background: var(--primary-st-color);
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter {
  background: var(--primary-st-color);
}

.complete-teaching,
.seen {
  background-color: #50e3c2;
}

#page-heading {
  font-size: 24px;
}

/*CUSTOM ant-design*/
.ant-layout-sider-trigger {
  background-color: var(--primary-st-color);
}

.ant-table-thead {
  background-color: var(--primary-st-color);
  color: white;
}

.ant-table-body {
  margin: 0 !important;
}

.ant-pagination a {
  font-weight: normal;
}

.table-max-height,
.max-height-layout .table-scroll {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.table-max-height.ant-table-wrapper {
  width: 100%;
  display: flex;
  max-height: 100%;
  flex-direction: column;
  overflow: auto;
}

.table-max-height.ant-table-wrapper .ant-spin-nested-loading,
.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container,
.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table,
.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content,
.table-max-height.ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table-content
  .ant-table-scroll
  .ant-table-placeholder {
  height: 100%;
}

.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content .ant-table-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content .ant-table-scroll .ant-table-header {
  flex: none;
}

.table-max-height.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content .ant-table-scroll .ant-table-body {
  flex: 1;
}

.table-max-height.ant-table-wrapper .ant-table .ant-table-filter-dropdown-link {
  font-weight: normal;
}
