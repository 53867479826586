<template>
  <a-layout-header class="header" :style="{ background: '#2a5f73' }">
    <div class="logo d-none d-md-inline" :style="{ float: 'left' }">
      <router-link to="/">
        <span class="logo-img"></span>
        <span v-text="$t('global.title')" class="navbar-title">StudyTrackingWebapp</span>
      </router-link>
    </div>
    <a-button type="primary" style="margin: 10px 0 0 20px" @click="toggleCollapsed" v-if="isMobile()">
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
    </a-button>

    <a-menu
      mode="horizontal"
      :style="{ float: 'right', background: '#2a5f73', color: '#ffffff', border: 'none', marginTop: '10px' }"
      :selected-keys="selectedKeys"
    >
      <a-sub-menu v-if="authenticated">
        <template #title>
            <div>
              <a-avatar size="large" icon="user" :src="avatar"/>
              <span class="no-bold d-none d-md-inline"> {{ authenticated }} </span>
            </div>
        </template>
        <a-menu-item key="settings">
          <router-link to="/account/settings">
            <a-icon type="setting" />
            <span v-text="$t('global.menu.account.settings')">settings</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="password">
          <router-link to="/account/password">
            <a-icon type="key" />
            <span v-text="$t('global.menu.account.password')">Password</span>
          </router-link>
        </a-menu-item>
        <a-menu-item v-on:click="logout">
          <a-icon type="logout" />
          <span v-text="$t('global.menu.account.logout')">Logout</span>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-header>
</template>

<script lang="ts" src="./jhi-navbar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.ant-layout-header .router-link-active {
  color: hsla(0,0%,100%,.5);
}

.ant-layout-header .navbar-title {
  color: #FFF;
  font-size: 1.25rem;
  line-height: inherit;
}

.ant-layout-header .logo .logo-img {
  height: 45px;
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  background: url('../../../content/images/logo-jhipster.png') no-repeat center center;
  background-size: contain;
  filter: drop-shadow(0 0 0.05rem white);
}

.ant-menu-item > a {
  font-weight: normal;
}

.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 2px solid #fff;
  text-shadow: 2px 2px 4px #000;
}
</style>
