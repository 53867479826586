<template>
  <div class="feedback-form">
    <a-button type="primary" shape="circle" icon="mail" size="large" @click="openFeedbackForm(true)"/>
    <a-drawer
      :title="$t('studyTrackingWebApp.feedbackForm.home.title')"
      :width="500"
      :mask-closable="false"
      :visible="showFeedbackForm"
      :body-style="{ paddingBottom: '80px' }"
      @close="openFeedbackForm(false)"
    >
      <a-form-model
        ref="ruleForm"
        :model="feedback"
        :rules="rules"
      >
        <a-form-model-item
          ref="provideInformation" :label="$t('studyTrackingWebApp.feedbackForm.incognito')"
          prop="provideInformation">
          <a-switch v-model="feedback.incognito"/>
        </a-form-model-item>
        <a-form-model-item
          ref="fullName" :label="$t('studyTrackingWebApp.feedbackForm.fullName')" prop="fullName"
          v-if="!feedback.incognito">
          <a-input
            :max-length="150"
            v-model="feedback.fullName"
            @blur="
          () => {
            $refs.fullName.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="phoneNumber" :label="$t('studyTrackingWebApp.feedbackForm.phoneNumber')" prop="phoneNumber"
          v-if="!feedback.incognito">
          <a-input
            :max-length="15"
            v-model="feedback.phoneNumber"
            @blur="
          () => {
            $refs.phoneNumber.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="emailAddress" :label="$t('studyTrackingWebApp.feedbackForm.emailAddress')"
          prop="emailAddress" v-if="!feedback.incognito">
          <a-input
            :max-length="100"
            v-model="feedback.emailAddress"
            @blur="
          () => {
            $refs.emailAddress.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('studyTrackingWebApp.feedbackForm.category')" prop="categoryId">
          <a-select
            v-model="feedback.categoryId"
            :placeholder="$t('studyTrackingWebApp.feedbackForm.chooseCategory')"
            show-search
            option-filter-prop="id"
            :allowClear="true"
            :filterOption="(input, option) => {
                    return (
                      option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }"
          >
            <a-select-option v-for="item in feedbackCategories" :value="item.id" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="$t('studyTrackingWebApp.feedbackForm.feedback')" prop="feedback">
          <a-input :max-length="1000" v-model="feedback.feedback" type="textarea" :rows="6"/>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" class="text-center">
          <a-button @click="openFeedbackForm(false)">
            <span v-text="$t('entity.action.cancel')"></span>
          </a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="onSubmit">
            <span v-text="$t('studyTrackingWebApp.feedbackForm.send')"></span>
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script lang="ts" src="./feedback-form.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feedback-form {
  position: fixed;
  z-index: 300;
  right: 20px;
  bottom: 30px;
}
</style>
